import React from 'react';
import MyLogo from './logo/synera-logo-comprido-branco-768x192.png';


const LogoFull = () => {
  return <img height={50} src={MyLogo} />;
}


export default LogoFull;
